import { Controller } from "@hotwired/stimulus"
import signaturePad from "signature_pad"

export default class extends Controller {
  static targets = ["canvas", "output"]

  canvasTargetConnected() {
    this.padValue = new signaturePad(this.canvasTarget)
    this.padValue.addEventListener("endStroke",this.save.bind(this))
    this.resizeCanvas()
  }
 
  resizeCanvas() {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    this.canvasTarget.width = this.canvasTarget.offsetWidth * ratio;
    this.canvasTarget.height = this.canvasTarget.offsetHeight * ratio;
    this.canvasTarget.getContext("2d").scale(ratio, ratio);
    this.padValue.clear()
  }

  clear(){
    this.padValue.clear()
  }

  save(){
    // console.log("huh", this.padValue)
    this.outputTarget.value = this.padValue.toDataURL()
  }
}
