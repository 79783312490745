import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ["sourceSelect", "targetSelect"]
  static values = {
    url: String
  }

  update(){
    console.log("DynamicSelectController update:" + this.urlValue)
    Turbo.visit(this.urlValue+"?project_id="+ this.sourceSelectTarget.value+"&name="+this.targetSelectTarget.name + "&id="+this.targetSelectTarget.id) 
  }
}
