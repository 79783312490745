import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  connect(){
    console.log('Connected to ventilation_calculation_controller')
  }

  destroy_room(event){
    event.preventDefault();
    
    const roomRow = document.querySelector('tr#'+event.params.id);
    roomRow.remove();
  }

  add_room(event){
    event.preventDefault();
    
    const table = document.querySelector('table');
    const tbody = document.createElement('tbody');
    const newRoom = document.createRange();

    table.appendChild(tbody);
    newRoom.selectNodeContents(tbody);
    const replacedString = event.params.room.replace(/\[id_placeholder\]/g,Math.floor((Math.random() * 999999) + 1));
    console.log(replacedString);
    const newRoomHtml = newRoom.createContextualFragment(replacedString);
    const rooms = document.querySelector('tbody');

    rooms.appendChild(newRoomHtml);
  }
}
