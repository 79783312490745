import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="remove-animation"
export default class extends Controller {
  connect() {
    this.removeEffect = this.removeEffect.bind(this)
    this.element.addEventListener("transitionend", this.removeEffect)
    this.element.addEventListener("animationend", this.removeEffect)
  }

  disconnect() {
    this.element.removeEventListener("transitionend", this.removeEffect)
    this.element.removeEventListener("animationend", this.removeEffect)
  }

  removeEffect() {
    [...event.target.classList].reverse().forEach( (klass) => {
         if(klass.match(/^highlight/)) {
          event.target.classList.remove(klass);
         }
      })
  }
}
