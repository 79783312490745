import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="contract-form"
export default class extends Controller {
  static targets = ["devices", "amountOfDevices", "fixedPrice", "contractType", 'paymentMethod']

  connect() {
    this.addEventListenerToDevices()
  }

  addEventListenerToDevices(){
    var checkboxes = this.devicesTarget.querySelectorAll('input[type="checkbox"]')

    checkboxes.forEach((checkbox) => {
      checkbox.addEventListener('click', () => {
        this.countDevices()
      })
    })

    var contractTypeSelect = this.contractTypeTarget.querySelector('select')
    
    contractTypeSelect.addEventListener('change', () => {
      this.update_payment_method(contractTypeSelect)
    })
  }

  countDevices(){
    var checkedCheckboxes = this.devicesTarget.querySelectorAll('input[type="checkbox"]:checked')
    this.fixedPriceTarget.value = 99 + checkedCheckboxes.length * 30
    this.amountOfDevicesTarget.value = checkedCheckboxes.length
  }

  update_payment_method(element){
    var payment_method = this.paymentMethodTarget.querySelector('select')
    
    switch(element.value) {
      case '3': 
        payment_method.innerHTML = '<option value="mollie" selected="selected">mollie</option><option value="incasso">incasso</option><option value="factuur">factuur</option>'
        break;
      case '4':
        payment_method.innerHTML = '<option value="mollie" selected="selected">mollie</option><option value="incasso">incasso</option><option value="factuur">factuur</option>'
        break;
      default: 
        payment_method.innerHTML = '<option value="mollie">mollie</option><option value="incasso">incasso</option><option value="factuur" selected="selected">factuur</option>'
    }
  }
}
