import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="budget-line-calculations"
export default class extends Controller {
  static targets = ["amount", "price", "totalPrice", "button", "description"]

  connect() {
    this.amountTarget.addEventListener("input", this.calculateTotalPrice)
    this.priceTarget.addEventListener("input", this.calculateTotalPrice)
    this.buttonTarget.addEventListener("click", this.setPrice)
  }

  calculateTotalPrice = () => {
    const amount = this.amountTarget.value
    const price = this.priceTarget.value
    const totalPrice = amount * price
    this.totalPriceTarget.value = totalPrice
  }

  setPrice = () => {
    this.descriptionTarget.value = "Arbeidsloon"
    this.priceTarget.value = 35  
  }
}