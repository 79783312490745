import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["invoiceAmountWithoutVat", "linePrice", "invoiceLinesAmount", "trigger", "invoiceDifference", "approveButton"]

  connect(){
    this.linePriceTargets.forEach((linePrice) => {
      linePrice.addEventListener("change", this.updateInvoiceLinesAmount)
    })

    this.triggerTargets.forEach((trigger) => {
      trigger.addEventListener("change", this.updateInvoiceLinesAmount)
    })

    this.invoiceLinesAmountTarget.addEventListener("change", this.checkValidity)
  }

  sumLinePrices = () => {
    let sum = 0
    this.linePriceTargets.forEach((linePrice) => {
      sum += parseFloat(linePrice.value)
    })
    return sum.toFixed(2)
  }
  
  updateInvoiceLinesAmount = () => {
    this.invoiceLinesAmountTarget.value = this.sumLinePrices()
    this.invoiceDifferenceTarget.value = (this.invoiceAmountWithoutVatTarget.value - this.invoiceLinesAmountTarget.value).toFixed(2)
    // this.invoiceLinesAmountTarget.dispatchEvent(new Event('change'))
    this.checkValidity()
  }

  checkValidity = () => {
    var invoiceLinesAmount = Number(this.invoiceLinesAmountTarget.value);
    var invoiceAmountWithoutVat = Number(this.invoiceAmountWithoutVatTarget.value);

    if (invoiceLinesAmount != invoiceAmountWithoutVat){
      this.isInValid(this.invoiceLinesAmountTarget)
      this.isInValid(this.invoiceDifferenceTarget)
      this.isDisabled(this.approveButtonTarget)
    } else {
      this.isValid(this.invoiceLinesAmountTarget)
      this.isValid(this.invoiceDifferenceTarget)
      this.isEnabled(this.approveButtonTarget)
    }
  }

  isDisabled = (element) => {
    element.classList.add("disabled")
    element.classList.add("higlight")
  }

  isEnabled = (element) => {
    if(element.classList.contains("disabled")){
      element.classList.remove("disabled")
    }
    
  }

  isValid = (element) => {
    if(element.classList.contains("is-invalid")){
      element.classList.remove("is-invalid")
    }
    element.classList.add("is-valid")
    
  }

  isInValid = (element) => {
    if(element.classList.contains("is-valid")){
      element.classList.remove("is-valid")
    }
    element.classList.add("is-invalid")
  }
}

// import { Controller } from "@hotwired/stimulus"

// // Connects to data-controller="budget-line-calculations"
// export default class extends Controller {
//   static targets = ["amount", "price", "totalPrice", "button", "description"]

//   connect() {
//     this.amountTarget.addEventListener("input", this.calculateTotalPrice)
//     this.priceTarget.addEventListener("input", this.calculateTotalPrice)
//     this.buttonTarget.addEventListener("click", this.setPrice)
//   }

//   calculateTotalPrice = () => {
//     const amount = this.amountTarget.value
//     const price = this.priceTarget.value
//     const totalPrice = amount * price
//     this.totalPriceTarget.value = totalPrice
//   }

//   setPrice = () => {
//     this.descriptionTarget.value = "Arbeidsloon"
//     this.priceTarget.value = 35  
//   }
// }