import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["amount", "itemPrice", "linePrice", "maxAmount", "minAmount"]

  connect(){
    this.amountTarget.addEventListener("change", this.guardMaxAmount)

    // this.amountTarget.addEventListener("change", this.calculateLinePrice) ## called from guardMaxAmount
    this.itemPriceTarget.addEventListener("change", this.calculateLinePrice)
    this.linePriceTarget.addEventListener("change", this.calculateItemPrice)
  }

  guardMaxAmount = () => {
    if(this.hasMaxAmountTarget){
    
      if (Number(this.amountTarget.value) > Number(this.maxAmountTarget.value)){
        this.amountTarget.value = this.maxAmountTarget.value
      }
    }

    if(this.hasMinAmountTarget){
      if (Number(this.amountTarget.value) < Number(this.minAmountTarget.value)){
        this.amountTarget.value = this.minAmountTarget.value
      }
    }

    this.calculateLinePrice()
  }

  calculateLinePrice = () => {
    this.linePriceTarget.value = (Number(this.amountTarget.value) * Number(this.itemPriceTarget.value)).toFixed(2)
    this.linePriceTarget.dispatchEvent(new Event('change'))
    this.requestSubmit()
  }

  calculateItemPrice = () => {
    if (Number(this.amountTarget.value) != 0){
      this.itemPriceTarget.value = (Number(this.linePriceTarget.value) / Number(this.amountTarget.value)).toFixed(2)
      this.requestSubmit()
    }
    
  }

  requestSubmit = () => {
    this.amountTarget.form.requestSubmit()
  }
}



// import { Controller } from "@hotwired/stimulus"

// // Connects to data-controller="budget-line-calculations"
// export default class extends Controller {
//   static targets = ["amount", "price", "totalPrice", "button", "description"]

//   connect() {
//     this.amountTarget.addEventListener("input", this.calculateTotalPrice)
//     this.priceTarget.addEventListener("input", this.calculateTotalPrice)
//     this.buttonTarget.addEventListener("click", this.setPrice)
//   }

//   calculateTotalPrice = () => {
//     const amount = this.amountTarget.value
//     const price = this.priceTarget.value
//     const totalPrice = amount * price
//     this.totalPriceTarget.value = totalPrice
//   }

//   setPrice = () => {
//     this.descriptionTarget.value = "Arbeidsloon"
//     this.priceTarget.value = 35  
//   }
// }