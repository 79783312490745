import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = { appointmentId: Number }
  
  
  setDate(e) {
    let params = new URLSearchParams()
    params.append("date", e.target.value)
    params.append("appointment_id", this.appointmentIdValue)
    let url = "../get_slots_by_date"

    get(`${url}?${params}`, {
      responseKind: "turbo-stream"
    })
  }
}
