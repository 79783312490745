import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="signup-form-calculations"
export default class extends Controller {
  static targets = ['contractType', 'amountOfDevices', 'monthlyPrice']

  connect() {
    this.contractTypeTarget.addEventListener('change', this.calculateMonthlyPrice)
    this.amountOfDevicesTarget.addEventListener('input', this.calculateMonthlyPrice)
  }

  calculateMonthlyPrice = () => {    
    this.updateMonthlyPrice()
  }
 
  updateMonthlyPrice(){
    const contractType = document.querySelector('input[name="maintenance_signup_form[contract_attributes][contract_type_id]"]:checked').value
    const amountOfDevices = this.amountOfDevicesTarget.value

    fetch(`/maintenance/contract_types/${contractType}`)
      .then(response => response.json())
      .then((data) => {    
        this.monthlyPriceTarget.value = Number(data.base_price) + (Number(data.device_price) * Number(amountOfDevices))  
        this.monthlyPriceTarget.classList.add('highlight')
      })
  }
}
