import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js/auto";

const defaultOptions = { 
  responsive: true, 
  maintainAspectRatio: true,
  plugins: {
    tooltip: {
      mode: 'index',
      intersect: false,
    }
  } 
};

export default class extends Controller {
  static targets = ["canvas", "data"]
  static values = { type: String, labels: Array, dataset: Array, options: Array }

  connect(){
    this.render();
  }

  render(){
    new Chart(this.canvasTarget, {
      type: this.typeValue,
      data: {
        labels: this.labelsValue,
        datasets: this.datasetValue
      },
      options: defaultOptions
    })
  }
}


